body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.k-toolbar ,.k-grid-pager{
  border-color: lightgray !important;
}

.k-table ,.k-picker-md, .k-input-md {
  font-size: 0.9rem!important;
}

@media (min-width: 1400px) {
  .container, .container-lg,  .container-xxl {
    max-width: 1820px!important;
  }
}

/* 스크롤바 전체를 스타일링합니다 */
::-webkit-scrollbar {
  width: 7px!important; /* 스크롤바의 너비 */
  height: 7px!important;
}

/* 스크롤바의 트랙(바탕) 부분을 스타일링합니다 */
::-webkit-scrollbar-track {
  background: #f1f1f1!important; /* 트랙의 색상 */
}

/* 스크롤바의 핸들(움직이는 부분)을 스타일링합니다 */
::-webkit-scrollbar-thumb {
  background: #888!important; /* 핸들의 색상 */
}

/* ::-webkit-scrollbar-thumb:hover {
  background: #555!important; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555!important; 
  width: 8px!important; 
  height: 8px!important; 
}


::-webkit-scrollbar:hover {
  background: #555!important; 
  width: 8px!important; 
  height: 8px!important; 
} */


.loadingContainer svg{    
  position:absolute;
  top:calc(50% - 30px);
  left:calc(50% - 30px);}

  .k-pager-md .k-pager-sizes .k-dropdown-list, .k-pager-md .k-pager-sizes .k-dropdown, .k-pager-md .k-pager-sizes .k-dropdownlist, .k-pager-md .k-pager-sizes > select {
    width: 7em!important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
margin-top: 0!important;
margin-bottom: 0!important;
}